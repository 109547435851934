@import "../../../node_modules/bootswatch/dist/sandstone/variables";

.icon {
  margin-right: 10px;
  font-size: 1.2rem;
  color: $gray-700;
}

.groupHeader {
  padding-top: 25px;

  &:first-child {
    padding-top: 10px;
  }
}

.item {
  &:hover {
    background-color: $dark !important;
    color: #fff;

    i {
      color: #fff;
    }
  }
}