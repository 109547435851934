ul.tree {
  background-color: var(--bs-gray-100);
  padding: 15px;
  border: solid 1px var(--bs-gray-400);
}

ul.tree, .tree-node-group {
  list-style-type: none;
  font-size: 1.25rem;

  .tree-node {
    cursor: pointer;
    margin-bottom: 5px;

    .name {
      margin-left: 5px;
    }
  }
}